import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const SectionHeading = ({ children, className }) => (
  <h2
    className={classNames(
      'section__heading',
      className,
    )}
  >
    {children}
  </h2>
);

SectionHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SectionHeading.defaultProps = {
  children: undefined,
  className: undefined,
};

export default SectionHeading;
