// @todo enable next eslint rules
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import LazyImage from '../LazyImage';
import logo from '../../img/logo.svg';
import appStore from '../../img/ico_app-store.svg';
import instagram from '../../img/ico_instagram.svg';
import facebook from '../../img/ico_facebook.svg';

const FooterLogos = () => (
  <section className="section section-socials">
    <div className="container">
      <a href="/" className="section-socials__logo"><LazyImage src={logo} alt="cn.customwritings.com" /></a>
      <div className="section-socials__apps">
        <a className="section-socials__apps__item" rel="nofollow" href="https://collegehelp.onelink.me/GXVs/footer" aria-label="App Store"><LazyImage src={appStore} /></a>
      </div>
      <div className="section-socials__links">
        <a className="section-socials__links__item" href="//www.instagram.com/customwritings_hub/" rel="nofollow noopener"><LazyImage src={instagram} /></a>
        <a className="section-socials__links__item" href="//www.facebook.com/customwritingscom/" rel="nofollow noopener"><LazyImage src={facebook} /></a>
      </div>
    </div>
  </section>
);

export default FooterLogos;
