import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_Section.scss';


const Section = ({ children, className }) => (
  <section
    className={classNames(
      'section',
      className,
    )}
  >
    <div className="container">
      {children}
    </div>
  </section>
);

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Section.defaultProps = {
  children: undefined,
  className: undefined,
};

export default Section;
