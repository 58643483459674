import React from 'react';
import spark1 from '../../img/icons/icon_spark_1.svg';
import spark2 from '../../img/icons/icon_spark_2.svg';
import iconInfo from '../../img/icons/icon_info.svg';
import './style.scss';

const PayInTwoNotify = () => (
  <div className="payInTwoNotify">
    <div className="payInTwoNotify__inner">
      <i className="payInTwoNotify__icon-1">
        <img src={spark1} alt="" width="42" height="40" />
      </i>
      <div className="payInTwoNotify__content-wrapper">
        <div className="payInTwoNotify__tooltip">
          <p>
            您可以分两期付款!
          </p>
          <span className="payInTwoNotify__tooltip__icon">
            <img src={iconInfo} alt="" width="20" height="20" />
          </span>
          <span className="payInTwoNotify__tooltip__text">
            <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create?splitPayment=1">下订单时只需支付 50% 的价格</a>，并在您的论文准备好后使用我们的分期服务提交另一半。
          </span>
        </div>
      </div>
      <i className="payInTwoNotify__icon-2">
        <img src={spark2} alt="" width="42" height="40" />
      </i>
    </div>
  </div>
);

export default PayInTwoNotify;
