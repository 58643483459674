import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PayInTwoNotify from '../PayInTwoNotify';
import Header from '../Header';
import Footer from '../Footer';

import CookiePolicyDialog from '../CookiePolicyDialog';

import './_index.scss';


const Layout = ({
  children,
  pageWrapperClassName,
}) => (
  <div>
    <PayInTwoNotify />
    <div
      className={classNames(
        'main-wrapper',
        pageWrapperClassName,
      )}
    >
      <Header />
      <main>
        {children}
      </main>
      <Footer />

      <CookiePolicyDialog />
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  pageWrapperClassName: PropTypes.string,
};

Layout.defaultProps = {
  pageWrapperClassName: undefined,
};

export default Layout;
