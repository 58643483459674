import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// eslint-disable-next-line react/prop-types
const LazyImage = image => (
  <Fragment>
    <LazyLoadImage
      alt={image.alt}
      title={image.title}
      height={image.height}
      src={image.src}
      width={image.width}
    />
  </Fragment>
);

export default LazyImage;
