// @todo enable next eslint rules
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */
import React, { Component } from 'react';
import classNames from 'classnames';
import './_index.scss';
import logo from '../../img/logo.svg';

class Header extends Component {
  state = {
    openMenu: false,
    scroll: false,
  };

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
  }

  handleScroll = () => {
    const windowWidth = window.innerWidth <= 1024 ? 0 : 95;
    this.setState({
      scroll: window.scrollY > windowWidth,
    });
  };

  toggleMenu = () => {
    const windowWidth = window.innerWidth <= 1024;
    this.setState(
      ({ openMenu }) => ({ openMenu: !openMenu && windowWidth }),
      () => {
        const { openMenu } = this.state;
        if (openMenu && windowWidth) {
          document.getElementsByTagName('html')[0].classList.add('overflow');
          document.getElementsByTagName('body')[0].classList.add('menu--open');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('overflow');
          document.getElementsByTagName('body')[0].classList.remove('menu--open');
        }
      },
    );
  };

  render() {
    const {
      openMenu,
      scroll,
    } = this.state;

    return (
      <header className={classNames(
        'header header--position-sticky',
        scroll && 'header--sticky',
        openMenu && 'show menu-open',
      )}>
        <div className="container">
          <a href="/" className="logo"><img src={logo} alt="CustomWritings.com - Writing Service"/></a>
          <div
            onClick={() => this.toggleMenu()}
            data-action="toggle-menu"
            className={classNames(
              'header__toggle-menu',
              openMenu && 'header__toggle-menu--active',
            )}
            aria-label="toggle menu"><span/></div>
          <div
            className={classNames(
              'header__menu-wrapper',
              openMenu && 'header__menu-wrapper--open',
            )}
          >
            <div className="header-top">
              <div className="container">
                <a className="header-top__phone" href="tel:+44-808-178-5340">44-808-178-5340</a>
                <a className="header-top__orders" href="https://www.customwritings.com/dashboard/authorization/" rel="nofollow">订单管理</a>
              </div>
            </div>
            <ul className="header__menu">
              <li className="header__menu__item"><a href="https://www.customwritings.com/pricing.html" rel="nofollow">代写服务价格</a></li>
              <li className="header__menu__item"><a href="https://www.customwritings.com/essay-writer.html" rel="nofollow">聘请作家</a></li>
              <li className="header__menu__item"><a href="https://www.customwritings.com/our-essay-samples.html" rel="nofollow">范文</a></li>
              <li className="header__menu__item"><a href="https://www.customwritings.com/contact-us.html" rel="nofollow">联系我们</a></li>
            </ul>
            <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="btn">马上下单</a>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
