/* eslint-disable no-undef */
import React, { Component } from 'react';


class CookiePolicyDialog extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    const style = document.createElement('link');
    script.src = '/assets/cookie-policy/cookie-policy-widget.js';
    script.async = true;
    script.onload = () => {
      __CWT__.CookiePolicy(document.body, {
        message: '我们网站通过cookies确保在网站上为您提供最佳体验。如果继续使用不更改设置，您将收到所有cookies。',
        linkText: '阅读更多',
        btnText: '确定',
      });
    };
    style.rel = 'stylesheet';
    style.href = '/assets/cookie-policy/widget.css';

    this.instance.appendChild(style);
    this.instance.appendChild(script);
  }

  render() {
    return (
      <div ref={(el) => { this.instance = el; }} />
    );
  }
}

export default CookiePolicyDialog;
